/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	var Sage = {
	// All pages
	'common': {
		init: function() {
			
			$('a:not([data-toggle]):not([rel]):not(#cn-accept-cookie)').on('click', function(e){
				e.preventDefault();
				$href = this.href;
				
				//$('#wptime-plugin-preloader').delay(300).fadeIn("slow");
				$('#wptime-plugin-preloader').addClass('preloader-fadein');
				
				var goLink = function(){
					window.location = $href;
				}
				
				setTimeout(function(){
					$('#wptime-plugin-preloader').removeClass('preloader-fadein');
					goLink();
				}, 2000);
			});
			
			// PARALAX CLOUDS
			
			$(window).scroll(function(){
				
				var wScroll = $(this).scrollTop();
				
				$('section.clouds .cloud-1').css({
					'transform' : 'translate3d(0, -'+ wScroll / 20 +'px, 0)'
				});
				
				$('section.clouds .cloud-2').css({
					'transform' : 'translate3d(0, '+ wScroll / 20 +'px, 0)'
				});
				
			});
			
			// IMAGES FOR NORMAL AND RETINA DEVICES
			
			var Retina = Retina || {};
			 
			Retina = {
			  init: function() {
			    var images = document.querySelectorAll("img[data-1x]");
			    if (Retina.isRetina() == true) {
			      Array.prototype.forEach.call(images, function(el, i) {
			        var src = el.getAttribute("data-2x");
			        el.setAttribute("src", src);
			      });
			    } else {
			      Array.prototype.forEach.call(images, function(el, i) {
			        var src = el.getAttribute("data-1x");
			        el.setAttribute("src", src);
			      });
			    }
			  },
			 
			  isRetina: function() {
			    if (window.devicePixelRatio > 1) {
			      return true;
			    } else {
			      return false;
			    }
			  }
			};
			
			Retina.init();
			
			// ANIMATIONS
  	
			function isScrolledIntoView(elem) {
				var $elem = $(elem);
				var $window = $(window);
				
				var docViewTop = $window.scrollTop();
				var docViewBottom = docViewTop + $window.height();
				if($elem.length) {
					var elemTop = $elem.offset().top;
					var elemBottom = elemTop + $elem.height();
				}
				
				return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
			}
			
			$(document).on( 'scroll', function(){
				if(isScrolledIntoView('.animation-wrapper')){
					$('.car-container').addClass('animating');
			  	}
			  	if(isScrolledIntoView('section.clouds .clouds-container')){
					$('section.clouds').addClass('animating');
			  	}
			  	
			  	$('.table-reminder').each(function(){
				  	var $this = $(this);
				  	if(isScrolledIntoView($this)){
						$this.addClass('animating');
				  	}
				  	
			  	});
			  	
			});
		
			
			// MOVE TO SECTION BY HASH
			
		    var hash = window.location.hash;
		    if(hash) {
			   setTimeout(function(){
				   $('html, body').animate({
				       scrollTop: $(hash+'_hash').offset().top
				    }, 1000);
			   }, 500)
		    }
			
			// NEWSLETTER
			
			$('footer form').submit(function(event) {

		        var formData = {
		            'email'              : $('input[name=email]').val(),
		            'imie'              : $('input[name=imie]').val()
		        };
		
		        
		        $.ajax({
		            type        : 'POST',
		            url         : site_url+'/freshmail.php',
		            data        : formData, 
		            dataType    : 'json',
		            encode          : true
		        })
	            .done(function(data) {
	                if (!data.success) {
		                $('footer form .alert').remove();
			            if (data.errors.email) {
			                $('footer form').append('<div class="alert alert-fail">' + data.errors.email + '</div>');
			                setTimeout(function(){
				                $('footer form .alert').remove();
			                }, 2000);
			            }
			
			        } else {
				        $('footer form .alert').remove();
			            $('footer form').append('<div class="alert alert-success">' + data.message + '</div>');
			            setTimeout(function(){
			                $('footer form .alert').remove();
		                }, 2000);
			        }
	            })
	            .fail(function(data) {
		            $('footer form .alert').remove();
			        $('footer form').append('<div class="alert alert-fail">Wystąpił błąd</div>');
			        setTimeout(function(){
		                $('footer form .alert').remove();
	                }, 2000);
			    });
	
		        event.preventDefault();
		    });
			
			// WOW.js INIT
			
			new WOW().init();
			
			// LB options
			
			lightbox.option({
		      'albumLabel': "Zdjęcie %1 z %2",
		    });
		    
		    // WARNING INFO MODAL
		    
		    if ($.cookie("warning_info") == null) {
			
				function show_modal(){
					$('.warningInfo').modal('show');
				}
			
				window.setTimeout(show_modal, 1000);
			}
			
			function setMyCookie() {
				var date = new Date();
				date.setTime(date.getTime()+(24*60*60*1000));
				
				document.cookie = "warning_info=true; expires="+date.toGMTString();
			}
			
			$('.warningInfo').on('hidden.bs.modal', function () {
				setMyCookie();
			});
			
			$(".warningInfo .close").on('click', function() {					
				setMyCookie();
			});	
			
			// SCROLL TO TOP
			
			$('.go-up').on('click', function(){
				$('html, body').animate({ scrollTop: 0 }, 500);
			});
			
		},
		finalize: function() {
	    	
		}
	},
	'home': {
		init: function() {			
			var $draggable = $('.divider').draggabilly({
				axis: 'x',
				containment: '.fk-container'
			})
			
			var draggie = $draggable.data('draggabilly');
			
			$draggable.on( 'dragMove', function() {
				var value = draggie.position.x + 28;
				$('.slide2-wrapper').css({'transform' : 'translateX('+value+'px)'});
				$('.slide2-image').css({'transform' : 'translateX(-'+value+'px)'});
			});
		  
		    // SLIDER
			
			$('.fk-slider').slick({
				dots: true,
				infinite: true,
				speed: 500,
				fade: true,
				cssEase: 'linear',
				autoplay: true,
				autoplaySpeed: 3000,
			});
			
			
		},
		finalize: function() {
	    	// JavaScript to be fired on the home page, after the init JS
		}
	},
	'o_nas': {
		init: function() {
		    // PARALAX ABOUT
		
			$(window).scroll(function(){
				
				var wScroll = $(this).scrollTop();
				
				$('.about .castle').css({
					'transform' : 'translate3d(0, '+ wScroll / 10 +'px, 0)'
				});
				
				$('.about .doors').css({
					'transform' : 'translate3d(-50%, -'+ wScroll / 20 +'px, 0)'
				});
		
				$('.about .cloud-1').css({
					'transform' : 'translate3d(0, '+ wScroll / 3 +'px, 0)'
				});
				
				$('.about .cloud-2').css({
					'transform' : 'translate3d(0, '+ wScroll / 5 +'px, 0)'
				});	
				
			});
			
			// APPLY FORM
			
			$('.modal form').submit(function(event) {
		    	event.preventDefault();
		
		    	t = jQuery( this );
		
				var empty = 0;
				$(".modal input[name^='job']").each(function() {
		      		var val = $.trim($( this ).val());
					if( val === '' ) {
						empty++;
					}
				})
				
				
				if( empty == 0 ) {
					$('.msg.error2, .msg.error').slideUp();
					
				    jQuery.ajax({
				      type: 'POST',
				      url: admin_ajax,
				      data: new FormData( this ),
				      processData: false,
				      contentType: false,
				      dataType: 'json',
				      action: 'app_job_ajax',
				      success: function( data )
				      {
				        if( data.output ) {
							$('.msg.ok').slideDown( 500 );
						} else {
							$('.msg.error').slideDown( 500 );
						}
				      },
				      error: function( data )
				      {
				        if( data.output ) {
				        	t.slideUp( 10000, function() {
								$('.msg.error').slideDown();
							})
						}
				      }
				    });
				} else {
					$('.msg.error2').slideDown();
				}
			});
		
			$("input[type='file']").change(function(e) {
				
				t = jQuery(this);
				l = jQuery(".labfile");
				
				
				if ( t.val() == '' ) {
					t.find('.text').html('Dodaj pliki aplikacyjne')
				} else {
					l.find('.text').html('Załącznik został dodany do wiadomości | <a href="#" class="fileclr">usuń załącznik</a>');
					jQuery('.fileclr').click(function() {
						t.val('');
						l.find('.text').html('Dodaj pliki aplikacyjne');
						return false;
					});
				}
			});
			
	 	}
	},
	'oferta': {
		init: function() {
			// OWL			
			var owl = $('.owl-carousel');
			owl.owlCarousel({
				loop:true,
				margin:30,
				nav:false,
				items:1,
			});
			
			$('.owl-prev').on('click', function() {
				$(this).parents('.carousel').find('.owl-container').trigger('prev.owl.carousel', [300]);
			});
			$('.owl-next').on('click', function() {
				$(this).parents('.carousel').find('.owl-container').trigger('next.owl.carousel', [300]);
			});
		}
	},
	'urodziny': {
		init: function() {
			// OWL			
			var owl = $('.owl-carousel');
			owl.owlCarousel({
				loop:true,
				margin:30,
				nav:false,
				items:1,
			});
			
			$('.owl-prev').on('click', function() {
				$(this).parents('.carousel').find('.owl-container').trigger('prev.owl.carousel', [300]);
			});
			$('.owl-next').on('click', function() {
				$(this).parents('.carousel').find('.owl-container').trigger('next.owl.carousel', [300]);
			});
		}
	},
	'galeria': {
	  	init: function() {
			// CUSTOM SELECT
			$('select').selectpicker();
			
			// GALLERY
			$('.thumbnails-wrapper').fkgallery();
		}
	},
	'faq': {
	  	init: function() {
			// ACCORDIONS
			$('.question button').on('click', function(){
				var icon = $(this).find('i');
				
				$(this).parents('.single-q').find('.answer').toggleClass('active');
				icon.toggleClass('fa-angle-down').toggleClass('fa-angle-up');
				
				var hide = $(this).data('hide');
				var show = $(this).data('show');				
				
				if(icon.hasClass('fa-angle-down')) {
					$(this).find('span').html(show);
				} else if(icon.hasClass('fa-angle-up')) {
					$(this).find('span').html(hide);
				}
			});
						
		}
	},
	'kontakt': {
	  	init: function() {
	  		
	  		$('select').selectpicker();
		  	
		  	// TOOLTIPS
			$('body').tooltip({
			  selector: '[data-toggle="tooltip"]',
			  container: 'body'
			});
			
			// GOOGLE MAPS
			function initialize(){
				var mapOptions = {
					center: { lat: 53.1306273, lng: 18.1201977 },
					zoom: 13,
					disableDefaultUI: false,
					scrollwheel: false,
					navigationControl: false,
					mapTypeControl: false,
					scaleControl: false,
					draggable: false,
				};
				
				var marker = new google.maps.Marker({
					position: new google.maps.LatLng( 53.130689, 18.119886 ),
					icon: new google.maps.MarkerImage('../wp-content/themes/family_park/dist/images/marker.png', null, null, null, new google.maps.Size(90, 70))
				});
				
				var map = new google.maps.Map(document.getElementById('map'), mapOptions);
				
				
				marker.setMap(map);
				
				google.maps.event.addDomListener(window, 'resize', function() {
					map.setCenter(mapOptions.center);
				});
				
				google.maps.event.addListener(marker, 'click', function() {
				    window.open('https://www.google.pl/maps/place/Family+Park+Centrum+Zabaw+Rodzinnych,+Sala+Zabaw+dla+dzieci/@53.130689,18.119886,15z/data=!4m2!3m1!1s0x0:0xb5e669f0fc1ba?sa=X&ved=0ahUKEwj5n53Gg4HKAhUh8XIKHY-CCl8Q_BIIdzAQ', '_blank');
				});
				
				$('.btn-switcher').on('click', function(){
					var unlock_text = $(this).data('unlock');
					var lock_text = $(this).data('lock');					
					
					if($(this).hasClass('unlock')) {
						map.setOptions({draggable: true});
						$(this).toggleClass('unlock').toggleClass('lock');
						$(this).find('i').toggleClass('fa-unlock').toggleClass('fa-lock');
						$(this).find('span').html(lock_text);						
					} else if($(this).hasClass('lock')) {
						map.setOptions({draggable: false});
						$(this).toggleClass('lock').toggleClass('unlock');
						$(this).find('i').toggleClass('fa-lock').toggleClass('fa-unlock');
						$(this).find('span').html(unlock_text);
					}
				});
				
				
			}
			google.maps.event.addDomListener(window, 'load', initialize);
			
			
			
			// INFO BOX
			$('.info-box .toggle, .info-box h6').on('click', function(){
				$(this).find('i').toggleClass('fa-chevron-down').toggleClass('fa-times');
				$('.info-box h6 i').toggleClass('off');
				$('.info-box .content-wrapper').toggleClass('active');
				$('.info-box-wrapper').toggleClass('col-xs-12').toggleClass('col-xs-12').toggleClass('col-sm-5').toggleClass('col-md-6').toggleClass('col-md-4').toggleClass('col-md-5');
				
				setTimeout(function(){
					$('.info-box .content-wrapper').toggleClass('animating');
					$('.info-box-wrapper').toggleClass('animating');
				}, 500);
			});
		},
		finalize: function() {
	    	
		}
	}
	
	
	
	};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
	
})(jQuery); // Fully reference jQuery after this point.
